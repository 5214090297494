
import { Component, Vue } from "vue-property-decorator";
import { IUserProfile, IUserProfileUpdate } from "@/interfaces";
import { dispatchGetUsers, dispatchUpdateUser } from "@/store/admin/actions";
import { readAdminOneUser } from "@/store/admin/getters";

@Component
export default class EditUser extends Vue {
  public valid = true;
  public fullName: string = "";
  public email: string = "";
  public isActive: boolean = true;
  public setPassword = false;
  public password1: string = "";
  public password2: string = "";

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.reset();
  }

  public reset() {
    this.setPassword = false;
    this.password1 = "";
    this.password2 = "";
    this.$validator.reset();
    if (this.user) {
      this.fullName = this.user.full_name;
      this.email = this.user.email;
      this.isActive = this.user.is_active;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      if (this.setPassword) {
        updatedProfile.password = this.password1;
      }
      await dispatchUpdateUser(this.$store, {
        id: this.user!.id,
        user: updatedProfile,
      });
      this.$router.push("/main/admin/users");
    }
  }

  get user() {
    return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
  }
}
